<template>

  <blockquote v-if="type !== 'paraphrase'" class="media-reference" :class="{'media-reference--quote': type === 'quote'}">
    <div class="media-reference__text">{{ title }}</div>
    <cite class="media-reference__source">
      <a :href="link" target="_blank" rel="noopener" class="media-reference__link">{{ source }}</a>
    </cite>
  </blockquote>

  <div v-else class="media-reference">
    <div class="media-reference__text">{{ title }}</div>
    <div class="media-reference__source">
      <a :href="link" target="_blank" rel="noopener" class="media-reference__link">{{ source }}</a>
    </div>
  </div>

</template>

<script>

export default {
  name: 'MediaReference',
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    cite: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'headline',
      validator(val){
        return ['quote', 'paraphrase', 'headline'].includes(val)
      },
    },
  },
  computed: {
    source(){
      const hostname = new URL(this.link).hostname
      const domain = hostname.split('.').slice(-2).join('.')
      return this.cite ?? domain
    },
  },
}
</script>

<style scoped lang="sass">

.media-reference
  font-family: Arial, sans-serif
  font-weight: bold
  font-size: 20px
  color: #ccc
  position: relative
  
  @media (min-width: 500px)
    font-size: 25px

  &--quote
    .media-reference__text
      &::before,
      &::after
        content: '"'
    .media-reference__source
      &::before
        content: '—'


  &__source
    display: block
    font-size: 12px

    @media (min-width: 500px)
      font-size: 15px

  &__source,
  &__link
    color: #999
    transition: color .1s ease

    &:hover
      color: rgb(220, 50, 50)

</style>